<template>
  <date-range-picker      
      ref="refDate"
      :locale-data="{format: 'YYYY-MM-DD' }"              
      :singleDatePicker="false"
      :timePicker="false"              
      :showWeekNumbers="false"
      :showDropdowns="false"
      :autoApply="true"
      v-model="dateRange"
      @update="onDateRangeChange"                            
    >           
      <div slot="input" slot-scope="" style="min-width: 260px;">
        <v-combobox
            v-model="sNowSelectDate"
            class="mb-10"                 
            label="일시"      
            :error-messages="sDateError"
            v-on:change="onDateChange()"     
        >              
        </v-combobox>                 
      </div>    
  </date-range-picker>            
</template>
<script>
import Util from "../../../Util.js";
import DateRangePicker from './DateRangePicker.vue'

var sNowSelectDate = "";

export default {
  components: {    
    DateRangePicker,
  },
  model: {    
    event: 'update',
  },
  props: ['value'],  
  created() {
    this.sNowSelectDate = this.value;
    this.sStartDate = this.sNowSelectDate.substring(0,10);
    this.sEndDate = this.sNowSelectDate.substring(13,23);
  },
  data() {    
    return {            
      sDateError : "",
      sNowSelectDate,
      dateRange : {
        startDate:"",
        endDate:"",
      },
    }
  },
  methods: {

    SetDate(sDate){
      this.sNowSelectDate = sDate;
    },
    onDateRangeChange(daterange){            
      this.sStartDate = Util.sFormatDate(daterange.startDate);
      this.sEndDate = Util.sFormatDate(daterange.endDate);      
      this.sNowSelectDate = this.sStartDate + " ~ " +  this.sEndDate;           
      this.$emit('update', {startDate: this.sStartDate, endDate: this.sEndDate})
    },
    onDateChange(){
      // this.$refs.refDate.togglePicker(false, null);          
      //this.$refs.refDate.clickAway();
      this.$refs.refDate.CloseWindow(false);

      var bOkDate = false;
      if (this.sNowSelectDate.length == 23) {        //
        if (Util.checkDate(this.sNowSelectDate.substring(0,10))) {          
          if (Util.checkDate(this.sNowSelectDate.substring(13,23))) {
            if (this.sNowSelectDate.substring(0,10) <= this.sNowSelectDate.substring(13,23)) {
              bOkDate = true;
            }
          }
        }
      }
      if (bOkDate) {
        this.sStartDate = this.sNowSelectDate.substring(0,10);
        this.sEndDate = this.sNowSelectDate.substring(13,23);
        this.sNowSelectDate = this.sStartDate + " ~ " +  this.sEndDate;           
        this.$emit('update', {startDate: this.sStartDate, endDate: this.sEndDate})
      } else {        
        this.sDateError = "유효하지 않은 일자입니다.(YYYY-MM-DD)";
        setTimeout(() => {        
          this.sDateError = ""
          this.sNowSelectDate = this.sStartDate + " ~ " +  this.sEndDate;  
        }, 1000);
      }        
    },   
  }
};

</script>


